import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/Logo-up.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
             
                <div class="footer">
                    <img class="logoF" src={logo} />

                    <div class="copyright">© 2024 cockadoodles. All Rights Reserved</div>

                </div>

            </div>
        )
    }
}

export default Footer;

